import React, { Component } from 'react';
import { Utils, Registry } from '../../../Utils.js';
import {
    withRouter
} from 'react-router-dom';
import DropdownMenu  from 'react-dd-menu';
import HeaderBurger from "./HeaderBurger";
import { SignOut } from "@ty-npm-pkg/auth";


class AccountHeader extends Component {
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        this.state = {
            user: {},
            isSolutionsOptionsOpen: false,
            isProfileOptionsOpen: false,
            isMenuOpen: false,
            displayMenu: "none"
        };

        this.toggleProfileOptions = this.toggleProfileOptions.bind(this);
        this.closeProfileOptions = this.closeProfileOptions.bind(this);
        this.logout = this.logout.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

        this.mounted = false;
    }

    toggleMenu(){
        if(this.state.displayMenu === 'block')
            this.setState({displayMenu: "none"});
        else
            this.setState({displayMenu: "block"});
    }

    logout(){
        Registry.remove("user");
        const { protocol, host } = window.location;
        SignOut(`${protocol}//${host}`);
    }

    setUser = user => {
		if(user && this.mounted)
            this.setState({user: user});
    };

    componentDidMount(){
        this.mounted = true;
        let user = Registry.observe(this, "user", user => {
            if(this.mounted)
    			this.setUser(user);
		});
		this.setUser(user);
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    toggleProfileOptions(){
        this.setState({ isProfileOptionsOpen: !this.state.isProfileOptionsOpen });
    };

    closeProfileOptions(){
        this.setState({ isProfileOptionsOpen: false });
    };

    render() {
        const profileOptions = {
            isOpen: this.state.isProfileOptionsOpen,
            close: this.closeProfileOptions,
            toggle: <div onClick={() => this.toggleProfileOptions()} className="display-inline-block pink-hover cursor f-16 relative">
                <div className="marg-l-20">
                    {this.state.user.first_name ? this.state.user.first_name + " " + this.state.user.last_name : ""} <i className="vertical-align-middle f-20 marg-l-5 fa fa-angle-down" aria-hidden="true"> </i>
                </div>
            </div>,
            align: 'left',
        };

        return (
            <header id="header" className="fixed wp-100 " style={{zIndex: "100000"}}>
                <div className={"wp-100 bg-" + this.props["background-color"] + " shadow-bottom center hide_below-md " + this.props["text-color"]}>
                    <div className="container">
                    <div className="col-md-12 f-14 relative header-content">
                        <a href="/" className="cursor background-contain-center h-66 w-80 f-32 lh-66 pull-left display-inline-block" style={{backgroundImage: 'url("'+this.props.backgroundImage+'")'}}>
                            &nbsp;
                        </a>
                        <div className="pull-left lh-66 marg-l-20">
                            <div className="pad-r-20 relative">
                                <div className="cursor lh-66 marg-r-30 marg-l-10 display-inline-block pink-hover">
                                    <div onClick={()=> Utils.goTo(this, "/commander")} className="uppercase medium btn_primary lh-40 pad-t-0-important pad-b-0-important">
                                        Commander
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="pull-right">
                            <div className="marg-l-10 pad-t-15 display-inline-block marg-r-30">
                                <div>
                                    <div>
                                        <i className="icon-phone" aria-hidden="true"> </i> 01 86 76 08 88
                                    </div>
                                    <div className="grey">
                                        6/7j - 9h à 20h
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.user ?
                                    <div className="display-inline-block relative pull-right pad-t-22" style={{minWidth: "150px"}}>
                                        <DropdownMenu {...profileOptions} className="display-inline-block">
                                            <div className={"wp-100 absolute pad-t-20 shadow-bottom bg-" + this.props["background-color"] + " " + this.props["text-color"]} >
                                                <div onClick={() => Utils.goTo(this, "/")} className="pad-10 cursor pink-hover lh-20">Accueil</div>
                                                <div onClick={() => this.logout()} className="pad-10 border-t-blue-1 cursor pink-hover lh-20">
                                                    <i className="icon-log-out"/> Se d&eacute;connecter                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </div> : ""
                            }
                        </div>
                    </div>
                    </div>
                </div>

                <div className="bg-grey_light display_below-md scrollable">
                    <HeaderBurger/>
                </div>
                
            </header>

        );
    }
}

AccountHeader.defaultProps = {
    "text-color": "blue_dark",
    "background-color": "white",
    "backgroundImage": require("../../../images/TankYou-v2-white.png")
};

export default withRouter(AccountHeader);
//status={404}