import React, { Component } from 'react';
import { Utils, Registry } from '../../Utils.js';
import {
    withRouter
} from 'react-router-dom';
import DropdownMenu  from 'react-dd-menu';
import HeaderBurger from './HeaderBurger';

import { SignOut } from "@ty-npm-pkg/auth";
import CovidInfoBlock from "../CovidInfo";


class Header extends Component {
    constructor(props){
        super(props);

        this.api = Registry.registred("api");

        let user = Registry.observe(this, "user", user => {
            if(this.mounted)
                this.setState({user:user});
        });

        this.state = {
            isSolutionsOptionsOpen: false,
            isProfileOptionsOpen: false,
            user: user,
            isMenuOpen: false,
            isMenuOpen2: false,
            displayMenu: "none"
        };

        this.toggleSolutionsOptions = this.toggleSolutionsOptions.bind(this);
        this.closeSolutionsOptions = this.closeSolutionsOptions.bind(this);
        this.toggleProfileOptions = this.toggleProfileOptions.bind(this);
        this.closeProfileOptions = this.closeProfileOptions.bind(this);
        this.logout = this.logout.bind(this);
        this.toggleMenu = this.toggleMenu.bind(this);

        this.mounted = false;
    }

    toggleMenu(){
        if(this.state.displayMenu === "block"){
            this.setState({displayMenu: "none"});
        }else{
            this.setState({displayMenu: "block"});
        }
    }

    logout(){
        Registry.remove("user");
        const { protocol, host } = window.location;
        SignOut(`${protocol}//${host}`);
    }

    componentDidMount(){
        this.mounted = true;
        let user = Registry.registred("user");
        if(user)
            this.setState({user: user});
    }

    componentWillUnmount(){
        this.mounted = false;
    }

    toggleProfileOptions(){
        this.setState({ isProfileOptionsOpen: !this.state.isProfileOptionsOpen });
    };

    closeProfileOptions(){
        this.setState({ isProfileOptionsOpen: false });
    };

    toggleSolutionsOptions(){
        this.setState({ isSolutionsOptionsOpen: !this.state.isSolutionsOptionsOpen });
    };

    closeSolutionsOptions(){
        this.setState({ isSolutionsOptionsOpen: false });
    };


    toggle = () => {
        this.setState({ isMenuOpen: !this.state.isMenuOpen });
    };
    
    close = () => {
        this.setState({ isMenuOpen: false });
    };

    toggle2 = () => {
        this.setState({ isMenuOpen2: !this.state.isMenuOpen2 });
    };
    
    close2 = () => {
        this.setState({ isMenuOpen2: false });
    };

    render() {
        const profileOptions = {
            isOpen: this.state.isProfileOptionsOpen,
            close: this.closeProfileOptions,
            toggle: <div onClick={() => this.toggleProfileOptions()} className="display-inline-block pink-hover cursor f-16 relative">
                <div className="marg-l-20">
                    {this.state.user ? this.state.user.first_name + " " + this.state.user.last_name : ""} <i className="vertical-align-middle f-20 marg-l-5 fa fa-angle-down" aria-hidden="true"> </i>
                </div>
            </div>,
            align: 'left',
        };

        return (
            <header id="header" className="fixed wp-100 h-xs-60 " style={{zIndex: "100000"}}>

            <CovidInfoBlock />

                <div className={"wp-100 bg-" + this.props["background-color"] + " " + (this.props["shadow-class"] ? "shadow-bottom" : "") + " center hide_below-md " + this.props["text-color"]}>
                    <div className="container f-14 relative header-content">
                        <a href="/" className="cursor background-contain-center h-66 w-80 f-32 lh-66 pull-left display-inline-block" style={{backgroundImage: 'url("'+this.props.backgroundImage+'")'}}>
                            &nbsp;
                        </a>
                        <div className="pull-left lh-66 marg-l-20 uppercase">
                            <div className="pad-r-20 pad-md-r-0 ty-flex ty-justify-content-around">
                                
                            </div>
                        </div>

                        <div className="pull-right">
                            <div className="marg-l-10 pad-t-23 display-inline-block marg-r-30">
                                        <i className="icon-phone" aria-hidden="true"> </i> 01 86 76 08 88
                            </div>
                            {
                                this.state.user ?
                                    <div className="display-inline-block relative pull-right pad-t-22" style={{minWidth: "150px"}}>
                                        <DropdownMenu {...profileOptions} className="display-inline-block">
                                            <div className={"wp-100 absolute " + (this.props["shadow-class"] ? "shadow-bottom" : "") + " bg-" + this.props["background-color"] + " " + this.props["text-color"]} style={{top: "67px", textAlign: "left", zIndex:"99999"}}>
                                                <div onClick={() => Utils.goTo(this, "/mon-compte/accueil")} className="pad-10 cursor pink-hover lh-20">Mon espace perso</div>
                                                <div onClick={() => this.logout()} className="pad-10 border-t-blue-1 cursor pink-hover lh-20">
                                                    <i className="icon-log-out"/> Se d&eacute;connecter
                                                </div>
                                            </div>
                                        </DropdownMenu>
                                    </div>
                                    :
                                    <div className="display-inline-block relative pull-right pad-t-12">
                                        <a href="/connexion" className="uppercase pink-hover medium display-inline-block cursor marg-r-30 f-12 lh-40" style={{paddingTop: "0px", paddingBottom:"0px"}}>
                                            Se connecter
                                        </a>
                                        <a href="/inscription" className="uppercase medium  btn_primary lh-40" style={{paddingTop: "0px", paddingBottom:"0px"}}>
                                            S'inscrire
                                        </a>
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="display_below-md2">
                    <HeaderBurger {...this.props} />
                </div>
            </header>

        );
    }
}

Header.defaultProps = {
    "text-color": "blue_dark",
    "background-color": "white",
    "backgroundImage": require("../../images/TankYou-v2.png"),
    "shadow-class": true
};

export default withRouter(Header);
