import React, { Component } from 'react';
import { Registry } from "../Utils";
import {
    withRouter,
    Redirect
} from 'react-router-dom';
import {NotificationContainer} from "react-notifications";

import AccountHeader from '../components/Account/Header/Header';
import Footer from "../components/Footer";

import AccountHome from '../components/Account/Home';
import AccountProfile from '../components/Account/Profile';
import AccountOrders from "../components/Account/Orders";
import AccountVehicles from "../components/Account/Vehicles";
import AccountPayments from "../components/Account/Payments";
import Utils from "../Utils";
import AccountAddresses from "../components/Account/Addresses";
import AccountZones from "../components/Account/Zones";
import { IsLogged, InitiateLogin } from "@ty-npm-pkg/auth";


let WebStorage = require('react-webstorage');


class Account extends Component {
    constructor(props){
        super(props);

		this.webStorage = new WebStorage(window.localStorage || window.sessionStorage);
        this.api = Registry.registred("api");

        this.state = {
            redirectHome: false,
            registered_fleet: false,
            registered_card: false
        };

        this.mobile = Utils.isMobile();
    }

    componentDidMount(){
		if(!IsLogged()){
            InitiateLogin();
		    this.setState({redirectHome: true});
        }
    }

    render() {
        if(this.state.redirectHome)
            return null;

        let block = "";

        switch(this.props.match.params.blockId){
            case "informations":
                block = <AccountProfile />;
                break;
            case "commandes":
                block = <AccountOrders />;
                break;
            case "vehicules":
                block = <AccountVehicles />;
                break;
            case "adresses":
                block = <AccountAddresses />;
                break;
            case "paiement":
                block = <AccountPayments card/>;
                break;
            case "tarifs-et-zones":
                block = <AccountZones />;
                break;
            case "accueil":
            default:
                block = <AccountHome />;
                break;
        }

        return (
            <div className="bg-grey_light hp-100">
                <AccountHeader background-color="blue_dark" text-color="white" backgroundImage={require("../images/TankYou-v2-white.png")} />
                <section className="bg-grey_light home-account">
                    <div className="container min-height-container">
                        <div className="col-md-4 hide_below-md">
                            <div className="col-md-12 block-content">
                                <ul>
                                    <li className={ (this.props.match.params.blockId === "accueil" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/accueil")}}>
                                        <i className="font-ty icon-house"> </i> Accueil
                                    </li>
                                    <li className={ (this.props.match.params.blockId === "informations" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/informations")}}>
                                        <i className="font-ty icon-user"> </i> Informations personnelles
                                    </li>
                                    <li className={ (this.props.match.params.blockId === "commandes" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/commandes")}}>
                                        <i className="font-ty icon-order"> </i> Commandes
                                    </li>
                                    <li className={ (this.props.match.params.blockId === "vehicules" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/vehicules")}}>
                                        <i className="font-ty icon-car"> </i> V&eacute;hicules
                                    </li>
                                    <li className={ (this.props.match.params.blockId === "adresses" ? "color-red-ty" : "") }  onClick={() => {Utils.goTo(this, "/mon-compte/adresses")}}>
                                        <i className="font-ty icon-address"> </i> Adresses
                                    </li>
                                    <li className={ (this.props.match.params.blockId === "paiement" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/paiement")}}>
                                        <i className="font-ty icon-credit-card"> </i> Paiement
                                    </li>
                                    <li className={ (this.props.match.params.blockId === "tarifs-et-zones" ? "color-red-ty" : "") } onClick={() => {Utils.goTo(this, "/mon-compte/tarifs-et-zones")}}>
                                        <i className="font-ty icon-gun-petrol"> </i> Tarifs & Zones
                                    </li>
                                    {/*<li className={ (this.props.match.params.blockId === "faq" ? "color-red-ty" : "") }>*/}
                                        {/*<i className="font-ty icon-question"> </i> FAQ*/}
                                    {/*</li>*/}
                                    {/*<li className={ (this.props.match.params.blockId === "app" ? "color-red-ty" : "") }>*/}
                                        {/*<i className="font-ty icon-smartphone"> </i> Application mobile*/}
                                    {/*</li>*/}
                                </ul>
                            </div>
							<div className="col-md-12 dash-link-content">
								<ul>
									<li className="">
                                        <a href="https://dashboard.tankyou.co/" target="_blank" rel="noopener noreferrer">
										    <i className="font-ty icon-business"> </i> Espace professionnel
                                        </a>
									</li>
								</ul>
							</div>
                        </div>

                        <div className="col-md-8">
                            { block }
                        </div>
                    </div>
                </section>
                <NotificationContainer />
                <Footer backgroundColor="blue_dark" textColor="white" />
            </div>
        );
    }
}

export default withRouter(Account);
