import { COMMON } from "./common";


const PROD_ENV = {
    baseUrl: "https://www.tankyou.co",
    APIUrl: "https://api.aws.tankyou.co/v2",

    auth: {
        Client: {
            ID: "55026765-2cc4-472c-9d9a-046f51371a6f",
            RedirectURI: "/handle_token",
            Scope: "full"
        },
        Server: {
            URL: "https://auth.tankyou.co",
            Endpoints: {
                Authorize: "/oauth/authorize",
                Token: "/oauth/token",
                Logout: "/user/logout"
            }
        }
    },

    stripe_token: "pk_live_zJvI9uIFK8AMGZdhmIVeG75u",


    ...COMMON
};

export default PROD_ENV;