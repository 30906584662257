import React, { Component } from "react";

import { Registry } from "../Utils";

import { 
    IsLogged, 
    SignOut, 
    HandleRedirectURIResponse
} from "@ty-npm-pkg/auth";


export class TokenHandler extends Component{
    constructor(props){
        super(props);
        this.api = Registry.registred("api");
        if(!this.api)
            console.error("Tankyou must be initialized");
        this.state = { error: false };
    }

    componentDidMount(){
        HandleRedirectURIResponse()
            .then(async () => {
                if(!IsLogged())
                    this.setState({error: true});
                else{
                    this.api.getUser()
                        .then(res => this.props.onSuccess(res))
                        .catch(_ => this.setState({error: true}));
                }
            })
            .catch(() => this.setState({error: true}));
    }

    retry = () => {
        const { protocol, host } = window.location;
        SignOut(`${protocol}//${host}`);
    }

    render(){
        const { error } = this.state;

        if(error){
            return <div className="loading-overlay">
                <i className="fa fa-exclamation-circle fa-3x fa-fw"></i><br /><br />
                Un erreur est survenue ...
                <br /><br />
                <div className="pink-hover underline pointer" onClick={this.retry}>R&eacute;essayer</div>
            </div>
        }

        return <div className="loading-overlay">
            <i className="fa fa-spinner fa-spin fa-3x fa-fw"></i><br /><br />
            Connexion ...
        </div>;
    }
}


TokenHandler.defaultProps = {
    onFailed: error => {},
    onSuccess: () => {}
};